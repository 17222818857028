import React, { FunctionComponent } from "react"
import { PageContextData } from "../contexts/pageContext"
import { graphql, Link } from "gatsby"
import * as styles from "./oldbrowser.module.css"
import Image from "../components/image/image"
import BlockContent from "../components/blockContent/blockContent"

export const query = graphql`
  query GetBaseSettings {
    result: sanityHeaderFooter {
      headerLinks {
        id
        title
        linkText
        category {
          id
          homepage {
            id
          }
        }
        slug {
          _type
          current
        }
      }
      footerLinks {
        id
        title
        linkText
        category {
          id
          homepage {
            id
          }
        }
        slug {
          _type
          current
        }
      }
      whiteLogo {
        _type
        asset {
          _id
          id
          url
        }
        hotspot {
          x
          y
          width
          height
        }
        crop {
          top
          left
          right
          bottom
        }
      }
      contactDetails {
        details {
          _key
          icon
          link {
            blank
            href
          }
          text
        }
      }
      colorLogo {
        _type
        asset {
          _id
          id
          url
        }
        hotspot {
          x
          y
          width
          height
        }
        crop {
          top
          left
          right
          bottom
        }
      }
      homepage {
        id
      }
      title
      socialLinks {
        details {
          icon
          link {
            blank
            href
          }
          text
        }
      }
      _rawOldBrowserMessage
    }
  }
`

const IndexPage: FunctionComponent<{ router?: string; pageContext: PageContextData }> = props => {
  const {
    data: { result },
  } = props
  return (
    <div className={styles.container}>
      <div>
        <div className={styles.logo}>
          <Image {...result.colorLogo} alt="Site logo" fit="contain" />
        </div>
        <div className={styles.message}>
          <BlockContent blocks={result._rawOldBrowserMessage} />
        </div>
      </div>
    </div>
  )
}

export default IndexPage
